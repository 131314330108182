<template>
	<div class="cs-block cs-jobs" :class="styleClass">
		<div class="cs-block-base">
			<div class="container">
				<CSJobs :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	components: {
		CSJobs: () => import("../components/CSJobs.vue"),
	},
	name: "Job",
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('Job')
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-jobs {
    &.cs-style-color ::v-deep {
        .job-card {
            background: #fff !important;
        }
    }
    &.cs-style-dark {
        ::v-deep {
            .dropdown-wrapper {
                span {
                    color: #fff !important;
                }
                .dropdown-select {
                    color: #fff !important;
                    border-color: #fff !important;
                }
                path {
                    fill: #fff !important;
                }
            }
        }
    }
	.header-content {
		text-align: center;
		margin-bottom: $header_margin;
	}
	::v-deep {
		.dropdown {
			&-wrapper {
				margin-bottom: 20px;
				span {
					@include small-title;
				}
				path {
					fill: #333;
				}
				svg {
					pointer-events: none;
				}
			}
			&-select {
				opacity: 1;
				border-radius: 4px;
				border-color: #333;
				font-weight: 400 !important;
				color: #333;
                background: transparent;
                cursor: pointer;
			}
		}
		.job-card {
			height: calc(100% - 30px);
			border-radius: 0;
			background: $bg_color;
            margin-top: $default_margin * 2;
            border: none;
			.body {
				padding-top: 10px;
			}
			img {
				border-radius: 0;
				padding: 15px;
                height: 150px;
                object-fit: cover;
                object-position: center;
			}
			.features {
				margin: 0;
				gap: 10px;
				margin-bottom: 15px;
				span {
					@include tag-btn;
                    background-color: #e2e2e2;
					margin: 0;
				}
			}
			h5 {
				@include small-title;
				margin-bottom: $default_margin;
			}
			span.description {
				@include text;
				max-height: 77px;
				overflow: hidden;
				padding-bottom: $default_margin;
				display: block;
				white-space: wrap;
				text-overflow: ellipsis;
				width: 100%;
				margin-bottom: 15px;

                *:last-child {
                    margin-bottom: 0px;
                }
			}
			p {
				@include text;
				span {
					color: $bg_dark;
				}
			}
			.button-group .button a {
				@include base-transition;
				background: $theme_color !important;
				color: $secondary_text_color !important;
				font-weight: 500;
				border: 1px solid $theme_color;
				min-width: 130px;
                border-radius: 0;
                padding: 15px;
				&:hover {
					background: transparent !important;
                    color: $secondary_text_color !important;
				}
			}
			.button {
				width: 100% !important;
				@media (max-width: 767.98px) {
					width: 70% !important;
				}
			}
		}

		.cs-modal span.cs-text * {
			width: 100%;
			color: $secondary_text_color !important;
		}

		.cs-modal span.cs-text {
			max-height: 600px;
			overflow: auto;
			display: block;
		}
	}
}
</style>
